<template>
  <div>
    <div class="container">
      <img src="@/assets/img/mock/AlgorithmGuidance/banner1.png" alt="" />
      <img src="@/assets/img/mock/AlgorithmGuidance/banner2.png" alt="" />
      <img src="@/assets/img/mock/AlgorithmGuidance/banner3.png" alt="" />
      <img src="@/assets/img/mock/AlgorithmGuidance/banner4.png" alt="" />
      <img src="@/assets/img/mock/AlgorithmGuidance/banner5.png" alt="" />
      <img src="@/assets/img/mock/AlgorithmGuidance/banner6.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "AlgorithmGuidance",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.container {
  img {
    width: 100%;
    vertical-align: top;
  }
}
</style>